<template>
  <b-container fluid>
    <b-row>
      <b-col cols="2">
        <b-card class="card-app-design">
          <div v-if="specification.description" class="design-group">
            <h6 class="section-label">
              Description
            </h6>
            <b-card-text class="font-small-2 mt-1 mb-2">
              {{ specification.description }}
            </b-card-text>
          </div>
          <div v-else class="design-group">
            <b-card-text class="font-small-3 text-muted">
              No description...
            </b-card-text>
          </div>

          <div v-if="specification.scope" class="design-group">
            <h6 class="section-label">
              Scope
            </h6>
            <b-card-text class="font-small-2 mt-1 mb-2">
              {{ specification.scope }}
            </b-card-text>
          </div>

          <div v-if="specification.default" class="design-group">
            <b-badge variant="light-primary">
              Default Specification
            </b-badge>
          </div>
          <div v-else>
            <b-button
              v-if="!specification.default"
              v-b-tooltip.hover.top="'Set this specification to be the default specification'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              class="mb-1"
              block
              @click="makeDefault"
            >
              Make Default
            </b-button>
          </div>

          <div class="design-group">
            <h6 class="section-label">
              Spec. Created
            </h6>
            <b-badge variant="light-info">
              {{ specification.created | shortDate }}
            </b-badge>
          </div>

          <div class="design-group">
            <h6 class="section-label">
              Details
            </h6>
            <p class="font-small-3 m-0">
              <span class="font-weight-bolder text-primary">{{ requirements.length }}</span> Requirement{{ requirements | handlePluralSuffix }}
            </p>
            <p class="font-small-3 m-0">
              <span class="font-weight-bolder text-primary">{{ issues.length }}</span> Issue{{ issues | handlePluralSuffix }}
            </p>
            <p class="font-small-3 m-0">
              <span class="font-weight-bolder text-primary">{{ baselines.length }}</span> Baseline{{ baselines | handlePluralSuffix }}
            </p>
          </div>

          <div v-if="specification.id" class="design-group">
            <h6 class="section-label mb-0">
              UUID
            </h6>
            <b-card-text class="font-small-1 mt-0 mb-2 select-all">
              {{ specification.id }}
            </b-card-text>
          </div>

          <!--<pre>{{ review }}</pre>-->

          <!--<div v-if="review">-->
          <!--  <div-->
          <!--    v-if="review.status === 'CLOSED'"-->
          <!--    class="design-group"-->
          <!--  >-->
          <!--    <h6 class="section-label">-->
          <!--      Review Status-->
          <!--    </h6>-->
          <!--    <b-badge-->
          <!--      variant="light-danger"-->
          <!--      class="mr-1 my-25"-->
          <!--    >-->
          <!--      <feather-icon-->
          <!--        icon="CheckCircleIcon"-->
          <!--        class="mr-25"-->
          <!--      />-->
          <!--      {{ review.status }}-->
          <!--    </b-badge>-->
          <!--  </div>-->
          <!--</div>-->

          <div
            v-if="specification.locked && !isReadyToBaseline"
            class="design-group"
          >
            <h6 class="section-label">
              Review Status
            </h6>
            <b-badge
              variant="light-danger"
              class="mr-1 my-25"
            >
              <feather-icon
                icon="CheckCircleIcon"
                class="mr-25"
              />
            <!--{{ review.status === 'OPEN' ? 'IN-REVIEW' : 'PENDING APPROVAL' }}-->
            </b-badge>
            <!-- TODO The below is commented out until the 'Requirements Locked' functionality is implemented. -->
            <!--<b-badge-->
            <!--  v-b-tooltip.hover.v-dark-->
            <!--  v-b-tooltip.hover.right="'All Requirements in this Specification are read-only while the Specification is in review.'"-->
            <!--  variant="light-danger"-->
            <!--&gt;-->
            <!--  <feather-icon-->
            <!--    icon="LockIcon"-->
            <!--    class="mr-25"-->
            <!--  />-->
            <!--  <span>REQUIREMENTS LOCKED</span>-->
            <!--</b-badge>-->
            <!-- TODO The above is commented out until the 'Requirements Locked' functionality is implemented. -->
          </div>
          <div
            v-if="specification.locked && isReadyToBaseline"
            class="design-group"
          >
            <h6 class="section-label">
              Review Status
            </h6>
            <b-badge
              variant="light-success"
              class="mr-1 my-25"
            >
              <feather-icon
                icon="CheckCircleIcon"
                class="mr-25"
              />
              READY TO BASELINE
            </b-badge>
            <!-- TODO The below is commented out until the 'Requirements Locked' functionality is implemented. -->
            <!--<b-badge-->
            <!--  v-b-tooltip.hover.v-dark-->
            <!--  v-b-tooltip.hover.right="'All Requirements in this Specification are read-only while the Specification is in review.'"-->
            <!--  variant="light-danger"-->
            <!--&gt;-->
            <!--  <feather-icon-->
            <!--    icon="LockIcon"-->
            <!--    class="mr-25"-->
            <!--  />-->
            <!--  <span>REQUIREMENTS LOCKED</span>-->
            <!--</b-badge>-->
            <!-- TODO The above is commented out until the 'Requirements Locked' functionality is implemented. -->
          </div>
          <div v-if="approval">
            <div
              v-if="approval.status === 'CLOSED'"
              class="design-group"
            >
              <h6 class="section-label">
                Approval Status
              </h6>
              <b-badge
                variant="light-danger"
                class="mr-1 my-25"
              >
                <feather-icon
                  icon="CheckCircleIcon"
                  class="mr-25"
                />
                {{ approval.status }}
              </b-badge>
            </div>
          </div>

          <!-- Action Buttons -->
          <!-- Start Review -->
          <b-button
            v-if="isEmpty(approval)"
            v-b-tooltip.hover.top="'Start a Review for this draft Specification'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="gradient-info"
            block
            @click="startReview"
          >
            Start Review
          </b-button>
          <!--<pre>{{ review }}</pre>-->

          <!-- Close Review -->
          <!--<b-button-->
          <!--  v-if="ifAllReviewersReject"-->
          <!--  v-b-tooltip.hover.right="'Close the current Review (can be opened later)'"-->
          <!--  v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
          <!--  variant="gradient-danger"-->
          <!--  block-->
          <!--  class="mt-75"-->
          <!--  @click="closeReview"-->
          <!--&gt;-->
          <!--  Close Review-->
          <!--</b-button>-->

          <!-- Open Review -->
          <!--<b-button-->
          <!--  v-if="isReviewClosed"-->
          <!--  v-b-tooltip.hover.right="'Open the closed Review'"-->
          <!--  v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
          <!--  variant="gradient-success"-->
          <!--  block-->
          <!--  class="mt-75"-->
          <!--  @click="openReview"-->
          <!--&gt;-->
          <!--  Open Review-->
          <!--</b-button>-->

          <!-- Finalise Review -->
          <!--          <b-button-->
          <!--            v-if="ifAllReviewersApprove"-->
          <!--            v-b-tooltip.hover.right="'Finalise the Review and start the Approval workflow'"-->
          <!--            v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
          <!--            variant="gradient-info"-->
          <!--            block-->
          <!--            class="mt-75"-->
          <!--            @click="finaliseReview"-->
          <!--          >-->
          <!--            Finalise Review-->
          <!--          </b-button>-->

          <!-- Create Snapshot -->
          <div v-if="approval.status === 'CLOSED'">
            <b-form-group
              label="Snapshot Description"
              label-for="txtSnapshotDescription"
              class="mt-1"
            >
              <b-form-input
                id="txtSnapshotDescription"
                v-model="snapshotDescription"
                placeholder="Enter Snapshot Description..."
                size="sm"
              />
            </b-form-group>
            <b-button
              v-b-tooltip.hover.right="'Create a Snapshot of the current state of this Specification'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="gradient-info"
              block
              class="mt-75 animate-pulse"
              @click="createSnapshot(snapshotDescription)"
            >
              Create Snapshot
            </b-button>
          </div>

          <!-- Create Baseline -->
          <b-form-group
            v-if="isReadyToBaseline"
            label="Baseline Version"
            label-for="txtBaselineVersion"
            class="mt-1"
          >
            <b-form-input
              id="txtBaselineVersion"
              v-model="baselineVersion"
              placeholder="Example: 1.0"
              size="sm"
            />
          </b-form-group>
          <div v-if="baselineLoading" class="text-center mt-1">
            <b-spinner class="mt-3" variant="primary" />
            <br>
            <span>Baseline processing...</span>
          </div>
          <b-button
            v-if="isReadyToBaseline && !baselineLoading"
            v-b-tooltip.hover.right="'Approve this Specification and create a baseline'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="gradient-info"
            block
            class="mt-75 animate-pulse"
            @click="createBaseline"
          >
            Create Baseline
          </b-button>

          <!-- Reject Approval -->
          <b-button
            v-if="ifAllApproversReject"
            v-b-modal.reject-spec-approval
            v-b-tooltip.hover.right="'Reject the current Approval'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="gradient-danger"
            block
            class="mt-75"
          >
            Reject Approval
          </b-button>
          <!-- ./Action Buttons -->
        </b-card>
      </b-col>

      <b-col>
        <b-row>
          <b-col>
            <!--            <review-card-->
            <!--              v-if="!isEmpty(review)"-->
            <!--              review-type="Review"-->
            <!--              :review-data="review"-->
            <!--              :collapsed="collapseReviewCard"-->
            <!--              @update-review="updateReview"-->
            <!--            />-->
            <review-card
              v-if="!isEmpty(approval)"
              review-type="ApprovalRequest"
              :review-data="approval"
              :collapsed="collapseApprovalCard"
              @update-review="updateApproval"
            />
            <!--<pre>Decision: {{ ifAllApproversReject }}</pre>-->
            <!--<pre>Approval: {{ approval }}</pre>-->
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <requirements-card :requirements-data="requirements" />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <issues-card :issues-data="issues" />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <custom-properties-card :custom-properties-data="customProperties" />
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <baselines-card :baselines-data="baselines" />
          </b-col>
          <b-col cols="6">
            <snapshots-card
              :snapshots-data="snapshots"
              @create="createSnapshot"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- Modals -->
    <reject-approval @rejected="rejectApproval" />
  </b-container>
</template>

<script>
import _ from 'lodash'
import store from '@/store'
import {
  onUnmounted, computed, watch, ref,
} from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import { VBTooltip } from 'bootstrap-vue'
import BaselinesCard from '@/views/Specification/cards/BaselinesCard.vue'
import IssuesCard from '@/views/Specification/cards/IssuesCard.vue'
import CustomPropertiesCard from '@/views/Specification/cards/CustomPropertiesCard.vue'
import RequirementsCard from '@/views/Specification/cards/RequirementsCard.vue'
import ReviewCard from '@/views/Specification/cards/ReviewCard.vue'
import SnapshotsCard from '@/views/Specification/cards/SnapshotsCard.vue'
import RejectApproval from '@/views/Specification/modals/RejectApproval.vue'
// eslint-disable-next-line import/extensions
import specificationHelpers from '@/views/Specification/specificationHelpers.js'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import specificationStoreModule from './specificationStoreModule'

export default {
  name: 'SpecificationPage',
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BaselinesCard,
    IssuesCard,
    CustomPropertiesCard,
    RequirementsCard,
    ReviewCard,
    SnapshotsCard,

    RejectApproval,
  },
  setup() {
    const STORE_MODULE_NAME = 'SpecificationPage'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, specificationStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      store.commit('app/CLEAR_DYNAMIC_PAGE_TITLE')
      store.commit(`${STORE_MODULE_NAME}/CLEAR`)
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
      }
    })

    // Route Params
    const { route, router } = useRouter()
    const routeParams = computed(() => route.value.params)

    const { resolveReviewStatusVariant } = specificationHelpers()

    const fetchSpecification = () => {
      // Legacy table needs to have spec set
      store.dispatch('requirementsTableLegacy/setSpecification', routeParams.value.specId)
      store.dispatch(`${STORE_MODULE_NAME}/fetchSpecification`, routeParams.value.specId)
    }

    watch(routeParams, () => { fetchSpecification() }, { immediate: true })

    // Specification data
    const specification = computed(() => store.state[STORE_MODULE_NAME].specification)
    // const review = computed(() => store.state[STORE_MODULE_NAME].review)
    const approval = computed(() => store.state[STORE_MODULE_NAME].approval)
    const requirements = computed(() => store.state[STORE_MODULE_NAME].requirements)
    const issues = computed(() => store.state[STORE_MODULE_NAME].issues)
    const customProperties = computed(() => store.state[STORE_MODULE_NAME].customProperties)
    const baselines = computed(() => store.state[STORE_MODULE_NAME].baselines)
    const snapshots = computed(() => store.state[STORE_MODULE_NAME].snapshots)

    // Review and Approval logic
    // const ifAllReviewersApprove = computed(() => {
    //   if (!_.isEmpty(review.value) && review.value.status === 'OPEN') {
    //     if (review.value.reviewers.length > 0) {
    //       return review.value.reviewers.every(r => r.decision === 'approved')
    //     }
    //   }
    //   return false
    // })
    // const isReviewFinalised = computed(() => review.value.status === 'FINALISED')
    // const ifAllReviewersReject = computed(() => {
    //   if (!_.isEmpty(review.value) && review.value.status === 'OPEN') {
    //     if (review.value.reviewers.length > 0) {
    //       return review.value.reviewers.every(r => r.decision === 'rejected')
    //     }
    //   }
    //   return false
    // })

    const startReview = () => { store.dispatch(`${STORE_MODULE_NAME}/startReview`, routeParams.value.specId) }
    // const isReviewClosed = computed(() => review.value.status === 'CLOSED')
    const updateReview = payload => { store.dispatch(`${STORE_MODULE_NAME}/updateReview`, payload) }
    const collapseReviewCard = ref(false)
    const baselineLoading = ref(false)
    // watch(isReviewFinalised, () => {
    //   collapseReviewCard.value = isReviewFinalised.value
    // }, { immediate: true })
    // watch(isReviewClosed, () => {
    //   collapseReviewCard.value = isReviewClosed.value
    // }, { immediate: true })

    const finaliseReview = () => {
      store.dispatch(`${STORE_MODULE_NAME}/finaliseReview`, routeParams.value.specId)
    }
    const closeReview = () => {
      store.dispatch(`${STORE_MODULE_NAME}/closeReview`, routeParams.value.specId)
    }
    const openReview = () => {
      store.dispatch(`${STORE_MODULE_NAME}/openReview`, routeParams.value.specId)
    }

    // Review text
    // const reviewText = () => {
    //   if (review.value.status === 'FINALISED') {
    //     return 'PENDING APPROVAL'
    //   } if (review.value.status === 'CLOSED') {
    //     return 'CLOSED'
    //   }
    //   return 'IN-REVIEW'
    // }

    const collapseApprovalCard = ref(false)
    const ifAllApproversApprove = computed(() => {
      if (!_.isEmpty(approval.value) && approval.value.status === 'OPEN' && !_.isEmpty(approval.value.reviewers)) {
        return approval.value.reviewers.every(r => r.decision === 'approved')
      }
      return false
    })
    const ifAllApproversReject = computed(() => {
      if (!_.isEmpty(approval.value) && approval.value.status === 'OPEN' && !_.isEmpty(approval.value.reviewers)) {
        return approval.value.reviewers.every(r => r.decision === 'rejected')
      }
      return false
    })
    const isReadyToBaseline = computed(() => (!_.isEmpty(approval.value) && ifAllApproversApprove.value))
    const updateApproval = payload => { store.dispatch(`${STORE_MODULE_NAME}/updateApproval`, payload) }
    const rejectApproval = reason => {
      const payload = {
        reason,
        action: 'Close',
      }
      console.log('Reject Specification View')
      store.dispatch(`${STORE_MODULE_NAME}/rejectApproval`, payload)
    }

    // Baseline
    const baselineVersion = ref('')
    const createBaseline = () => {
      // Make sure there is a baseline version, and stop blank lines
      if (baselineVersion.value.replace(' ', '') === '') {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Baseline Version Required',
            text: 'Please enter a baseline version in order to proceed',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        return
      }
      baselineLoading.value = true
      const payload = { version: baselineVersion.value }
      store.dispatch(`${STORE_MODULE_NAME}/createBaseline`, payload)
        .then(() => {
          // When `createBaseline` action is performed, depending on the outcome, the variable `baselineStatus` is set
          // If `createBaseline` fails due to an error, `baselineStatus` will be set to FAIL, otherwise PASS
          if (store.state[STORE_MODULE_NAME].baselineStatus === 'PASS') {
            baselineLoading.value = false
            collapseApprovalCard.value = true
          } else {
            baselineLoading.value = false
          }
        })
    }

    // Change default
    const makeDefault = () => {
      store.dispatch(`${STORE_MODULE_NAME}/setAsDefaultSpecification`, routeParams.value.specId)
    }

    // Snapshot
    const snapshotDescription = ref('') // Only to be used in the sidebar, the Snapshots card has its own variable
    const createSnapshot = value => {
      store.dispatch(`${STORE_MODULE_NAME}/createSnapshot`, { description: value })
        .then(() => {
          if (snapshotDescription.value !== '') snapshotDescription.value = ''
        })
    }

    return {
      specification,
      // review,
      approval,
      requirements,
      issues,
      customProperties,
      baselines,
      snapshots,
      collapseReviewCard,
      collapseApprovalCard,
      baselineLoading,
      fetchSpecification,

      // Review
      // ifAllReviewersApprove,
      // ifAllReviewersReject,
      // isReviewFinalised,
      // isReviewClosed,
      startReview,
      updateReview,
      finaliseReview,
      closeReview,
      openReview,
      // reviewText,

      // Approval
      ifAllApproversApprove,
      ifAllApproversReject,
      isReadyToBaseline,
      updateApproval,
      rejectApproval,

      // Baseline
      baselineVersion,
      createBaseline,

      // Change default
      makeDefault,

      // Snapshot
      snapshotDescription,
      createSnapshot,

      resolveReviewStatusVariant,
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/base/bootstrap-extended/include';

#btnAddReviewer:hover {
  color: white;
  box-shadow: 0 0 10px $success !important;
}
</style>
